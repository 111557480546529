$(document).ready(function() {
    $(document).keypress(
        function(event){
          if (event.which == '13') {
            event.preventDefault();
          }
    });
    
    $("#phone").inputmask("+7(999)999-99-99");
    $("#phone-cannot-go").inputmask("+7(999)999-99-99");
    $("#code").inputmask("9 9 9 9 9 9", { "placeholder": "–" }); 
    $("#datebirth").inputmask("99.99.9999", { "placeholder": "дд.мм.гггг" }); 
    $('.tabs-nav__button-submit, .login-set-sms1').click(function(e) {
        e.preventDefault();
        $('.tabs-nav__form__error').html('');
        $('.login-set-sms1').hide();
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('input[name="_token"]').val()
            }
        });
        $.ajax({
            url: '/code/set',
            method: 'post',
            data: {
                type: $('#login-message-type').val(),
                phone: myString = $('#login #phone').val().replace(/\D/g,''),
                captcha: $('#captcha').val(),
            },
            success: function(data){
                $('.send-code').removeClass("active");
                $('.cannot-go-code').removeClass("active");
                $('.check-code').addClass("active");
                $('.to-main').removeClass("active");
                $('.customers-phone').text($('#login #phone').val());
                $('#code').val('');
                $('#error').html('');
                $('.tabs-nav__form_code__error').html('');
                $('.login-set-sms1').hide();
                $('.login-set-sms2').show();
                var start = Date.now();
                var refreshIntervalId = setInterval(function() {
                    var delta = Date.now() - start; // milliseconds elapsed since start
                    var seconds = Math.floor(delta / 1000); // in seconds
                    if((30 - seconds) <= 0)
                    {
                        $('.login-set-sms1').show();
                        $('.login-set-sms2').hide();
                        clearInterval(refreshIntervalId);
                    }

                    if((30 - seconds) < 10)
                    {
                        seconds = "0" + (30 - seconds);
                    } else {
                        seconds = 30 - seconds;
                    }

                    $("#timer").text("00:" + seconds);
                }, 1000); // update about every second
            },
            error: function(error) {
                $('.send-code').addClass("active");
                $('.cannot-go-code').removeClass("active");
                $('.check-code').removeClass("active");
                $('.to-main').removeClass("active");
                $('.refresh-button').click();
                if (error.status === 422)
                {
                    $('.tabs-nav__form__error').html(error.responseJSON.message);
                }
            }
        });
    });

    $('.tabs-nav__button_code-submit').click(function(e) {
        e.preventDefault();
        $('.tabs-nav__form_code__error').html('');
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('input[name="_token"]').val()
            }
        });
        let code = $('#login input#code').val();
        let phone = $('#login #phone').val().replace(/\D/g,'');
        if(code)
        {
            $.ajax({
                url: '/code/login',
                method: 'post',
                data: {
                    code: code,
                    phone: phone
                },
                success: function(){
                    let base_url = window.location.origin;
                    window.location = base_url + '/code/login/registration/' + phone;
                },
                error: function(error) {
                    if (error.status === 422)
                    {
                        $('.tabs-nav__form_code__error').html(error.responseJSON.message);
                    }
                }
            });
        }
    });

    $('#phone').change(function(e) {
        $('#email').val('');
        $('.tabs-nav__form__error').html('');
        $('.tabs-nav__form_code__error').html('');
    });

    $('#email').change(function(e) {
        $('#phone').val('');
        $('.tabs-nav__form__error').html('');
        $('.tabs-nav__form_code__error').html('');
    });

    $('.refresh-button').click(function() {
        $.ajax({
            type: 'get',
            url: '/refresh-captcha',
            success:function(data) {
                $('.captcha-image').html(data.captcha);
            }
        });
    });

    $('.cannot-go').click(function(e) {
        e.preventDefault();
        $('.send-code').removeClass("active");
        $('.cannot-go-code').addClass("active");
        $('.check-code').removeClass("active");
        $('.to-main').removeClass("active");
    });

    $('.tabs-nav__button-cannot-go-submit').click(function(e) {
        e.preventDefault();
        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('input[name="_token"]').val()
            }
        });

        let phone = $('#phone-cannot-go').val().replace(/\D/g,'');
        if(phone)
        {
            $.ajax({
                url: '/code/consultation',
                method: 'post',
                data: {
                    phone: phone
                },
                success: function(){
                    $('.send-code').removeClass("active");
                    $('.cannot-go-code').removeClass("active");
                    $('.check-code').removeClass("active");
                    $('.to-main').addClass("active");
                },
                error: function(error) {
                    if (error.status === 422)
                    {
                        $('.tabs-nav__form_code__error').html(error.responseJSON.message);
                    }
                }
            });
        }
    });
});
